'use client';

import { keepPreviousData, useQuery } from '@tanstack/react-query';

import useStore from '@/app/_store/wishlist';
import { wishlistQueryCreator } from '@/store/wishlist';

const useWishlist = () => {
  const { list: listIds, toggle } = useStore();
  const idsByType = listIds.reduce(
    (acc: { diamondIds: number[]; variantIds: number[] }, el) => {
      if (el.type === 'diamond') {
        return { ...acc, diamondIds: [...acc.diamondIds, el.id] };
      }
      return { ...acc, variantIds: [...acc.variantIds, el.id] };
    },
    {
      diamondIds: [],
      variantIds: [],
    },
  );

  const { data, isLoading, isFetching } = useQuery({
    ...wishlistQueryCreator(idsByType),
    placeholderData: keepPreviousData,
  });

  const listVariantDiff = [...idsByType.variantIds];
  const listDiamondDiff = [...idsByType.diamondIds];

  const diamondsList = idsByType.diamondIds ? data?.diamonds || [] : [];
  const variantsList = idsByType.variantIds ? data?.variants || [] : [];

  if (data && !isLoading && !isFetching) {
    diamondsList.forEach((item) => {
      const index = listDiamondDiff.indexOf(item.id);
      if (index > -1) {
        listDiamondDiff.splice(index, 1);
      }
    });

    listDiamondDiff.forEach((id) => toggle({ id, type: 'diamond' }));
  }

  if (data && !isLoading && !isFetching) {
    variantsList.forEach((item) => {
      const index = listVariantDiff.indexOf(item.id);
      if (index > -1) {
        listVariantDiff.splice(index, 1);
      }
    });

    listVariantDiff.forEach((id) => toggle({ id, type: 'product' }));
  }

  return { diamondsList, variantsList, toggle, isLoading };
};

export default useWishlist;
