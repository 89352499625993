import { camelCase } from 'change-case';

import { DEFAULT_PER_PAGE } from '@/constants/general';
import {
  Diamond,
  DiamondAttribute,
  ExpandedDiamond,
  DiamondListParams,
  Attribute,
  DiamondAttributeCode,
} from '@/types/diamond';

import api, { PaginationParams, Response, ResponseWithPagination } from './api';

export const getDiamondAttributes = async () => {
  const result = await api<Response<DiamondAttribute[]>>({
    url: '/attribute-values',
  });

  return {
    data: Object.fromEntries(
      result.data.map((item) => [camelCase(item.code), item]),
    ),
  };
};

export const getDiamondList = (params?: DiamondListParams & PaginationParams) =>
  api<ResponseWithPagination<Diamond[]>>({
    url: '/diamonds',
    params: {
      ...params,
      perPage: params?.perPage || DEFAULT_PER_PAGE,
    },
  });

export const getDiamondRanges = () =>
  api<
    Response<{
      [k: string]: { min: number; max: number } | undefined;
    }>
  >({
    url: '/diamond-range',
  });

export const getDiamondShapes = async (params?: {
  displayOnHome?: 1;
  categoryId?: number;
}) =>
  api<Response<Attribute<DiamondAttributeCode.shape>[]>>({
    url: '/attribute-values/shapes',
    params,
  });

export const getDiamondInfo = (id: number) =>
  api<Response<ExpandedDiamond>>({
    url: `/diamonds/${id}`,
  });
