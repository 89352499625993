import camelcaseKeys from 'camelcase-keys';
import { serialize, Options as FormDataOptions } from 'object-to-formdata';
import QueryString from 'qs';
import snakecaseKeys, { Options as SnakecaseKeysOptions } from 'snakecase-keys';

export interface Response<T> {
  data: T;
}

export interface ResponseWithPagination<T> extends Response<T> {
  meta: {
    currentPage: number;
    from: number;
    to: number;
    lastPage: number;
    perPage: number;
    total: number;
  };
}

export interface PaginationParams {
  page?: number;
  perPage?: number;
}

type RequestBody =
  | Record<string, any>
  | Blob
  | FormData
  | null
  | undefined
  | number
  | string;

interface TransformData {
  formData?: boolean | FormDataOptions;
  snakeCase?: boolean | SnakecaseKeysOptions;
}

interface RequestConfig {
  url?: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers?: Record<string, string | number | undefined | null>;
  params?: any;
  data?: any;
  transformData?: TransformData;
  transformParams?: boolean | SnakecaseKeysOptions;
}

const api = async <T>(config: RequestConfig & {}) => {
  const {
    url,
    data: dataBeforeTransform,
    transformData = {
      formData: false,
      snakeCase: true,
    },
    params,
    transformParams = true,
    headers,
    method = 'GET',
  } = config;

  const transformRequest = (data: RequestBody): BodyInit | null | undefined => {
    if (typeof data === 'number') {
      return data.toString();
    }

    if (
      typeof data === 'string' ||
      data === null ||
      data === undefined ||
      data instanceof Blob ||
      data instanceof FormData
    ) {
      return data;
    }

    const dataInCorrectCase = transformData.snakeCase
      ? snakecaseKeys(
          data,
          transformData.snakeCase === true
            ? {
                deep: true,
                exclude: [/\b_([a-zA-Z0-9_]+)\b/],
              }
            : transformData.snakeCase,
        )
      : data;

    if (transformData.formData) {
      const formData = serialize(
        dataInCorrectCase,
        transformData.formData === true ? {} : transformData.formData,
      );

      return formData;
    }

    return JSON.stringify(dataInCorrectCase);
  };

  const getParamsString = () => {
    let qs = '';
    if (!params) {
      return '';
    }
    if (transformParams) {
      qs = QueryString.stringify(
        snakecaseKeys(params, transformParams === true ? {} : transformParams),
      );
    } else {
      qs = QueryString.stringify(params);
    }

    return `?${qs}`;
  };

  const data = transformRequest(dataBeforeTransform);
  const fullUrl = `${process.env.NEXT_PUBLIC_API_URL}${url}${getParamsString()}`;
  const response = await fetch(fullUrl, {
    method,
    headers: {
      Accept: 'application/json',
      ...(method !== 'GET' && data instanceof FormData
        ? {}
        : { 'Content-type': 'application/json' }),
      ...headers,
    },
    body: data,
    next: { revalidate: 120 },
  });

  const isJson = response.headers.get('content-type') === 'application/json';

  if (!response.ok) {
    const errorData = isJson ? await response.json() : response;

    if (errorData && typeof errorData === 'object') {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw camelcaseKeys(errorData as Record<string, unknown>, {
        deep: true,
      });
    }
    throw errorData;
  }

  if (isJson) {
    const parsedResponse = await response.json();
    return camelcaseKeys(parsedResponse, { deep: true }) as T;
  }

  return response as T;
};

export default api;
