import { Settings } from '@/types/general';

import api, { Response } from './api';

export const getSettings = () =>
  api<Response<Settings>>({
    url: '/settings',
  });

interface LocationUnit {
  id: number;
  name: string;
  code: string;
}

export const getCountries = () =>
  api<Response<(LocationUnit & { states: LocationUnit[] })[]>>({
    url: '/countries',
  });

export const validatePostalCode = (data: {
  postalCode: string;
  countryCode: string;
  stateCode: string;
}) => api({ url: '/postal/validate', method: 'POST', data });
