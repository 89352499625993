import camelcaseKeys from 'camelcase-keys';

import { APP_NAME } from '@/constants/general';
import { Settings } from '@/types/general';
import { BasePageData } from '@/types/pages';

export const modifiedSettings = (settings: Settings) => {
  // https://workflow.arixess.com/projects/gt-jewellers/wiki/Value_for_Settings
  const result = settings.reduce(
    (acc: Record<string, string | null>, el) => ({
      ...acc,
      [el.key]: el.value,
    }),
    {},
  );

  return camelcaseKeys(result);
};

export const getBaseMeta = (data: Partial<BasePageData>) => ({
  title: data.title ? `${data.title} | ${APP_NAME}` : undefined,
  description: data.description ? JSON.stringify(data.description) : undefined,
  keywords: data.keywords ? JSON.stringify(data.keywords) : undefined,
});

export const serializeAndStringifyParams = (obj: Object) =>
  JSON.stringify(
    Object.fromEntries(
      Object.entries(obj).sort((a, b) => a[0].localeCompare(b[0])),
    ),
  );
