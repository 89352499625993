import { getCountries, getSettings } from '@/api/general';
import { modifiedSettings } from '@/helpers/general';

const keys = {
  settings: 'settings/list',
  countries: 'countries/list',
  empty: 'empty',
};

export const settingsQueryCreator = () => ({
  queryKey: [keys.settings],
  queryFn: async () => {
    const { data } = await getSettings();
    return modifiedSettings(data);
  },
});

export const countriesQueryCreator = () => ({
  queryKey: [keys.countries],
  queryFn: getCountries,
});

export const emptyQueryCreator = () => ({
  queryKey: [keys.empty],
  queryFn: async () => null,
});
