import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface State {
  list: number[];

  toggle: (id: number) => void;
}

const useDiamondCompareList = create<State>()(
  persist(
    (set) => ({
      list: [],
      toggle(item) {
        set((state) => {
          const filteredList = state.list.filter((id) => id !== item);

          if (state.list.length === filteredList.length) {
            return { list: [...filteredList, item] };
          }

          return {
            list: filteredList,
          };
        });
      },
    }),
    {
      name: 'diamond-compare-list',
      version: 1.0,
    },
  ),
);

export default useDiamondCompareList;
