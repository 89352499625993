import { getDiamondList } from '@/api/diamond';
import { getProductVariantList } from '@/api/product';

export const keys = {
  all: 'wishlist/all',
};

export const wishlistQueryCreator = (params: {
  diamondIds: number[];
  variantIds: number[];
}) => ({
  queryKey: [
    keys.all,
    `${params.diamondIds.join(',')},${params.variantIds.join(',')}`,
  ],
  queryFn: async () => {
    const { variantIds, diamondIds } = params;

    const variants = variantIds.length
      ? (await getProductVariantList({ variantIds })).data
      : [];

    const diamonds = diamondIds.length
      ? (await getDiamondList({ diamondIds })).data
      : [];

    return { variants, diamonds };
  },
});
