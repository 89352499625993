'use client';

import { keepPreviousData, useQuery } from '@tanstack/react-query';

import useCompare from '@/app/_store/diamond-compare-list';
import { diamondListQueryCreator } from '@/store/diamond';
import { emptyQueryCreator } from '@/store/general';

const useDiamondCompareList = () => {
  const { list, toggle } = useCompare();

  const { data, isLoading, isFetching } = useQuery(
    list.length
      ? {
          ...diamondListQueryCreator({ diamondIds: list }),
          placeholderData: keepPreviousData,
        }
      : emptyQueryCreator(),
  );

  const listDiff = [...list];

  const diamondsList = list.length ? data?.data || [] : [];

  if (data && !isLoading && !isFetching) {
    diamondsList.forEach((item) => {
      const index = listDiff.indexOf(item.id);
      if (index > -1) {
        listDiff.splice(index, 1);
      }
    });

    listDiff.forEach((id) => toggle(id));
  }

  return { data: diamondsList, isLoading, toggle };
};

export default useDiamondCompareList;
