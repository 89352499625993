import {
  CategoryAttribute,
  ListProduct,
  Metal,
  MetalGroup,
  NewReviewData,
  ProductCategory,
  ProductWithVariants,
  Promotion,
  Review,
} from '@/types/product';

import api, { ResponseWithPagination, PaginationParams, Response } from './api';

export const getProductVariantList = (
  params?: PaginationParams & {
    expensiveFirst?: 1;
    cheaperFirst?: 1;
    shapeId?: number;
    metalId?: number;
    minPrice?: number;
    maxPrice?: number;
    categoryId?: number;
    trendNow?: 1;
    newCollection?: 1;
    styleId?: number;
    variantIds?: number[];
    perfectProductSlug?: string;
    carat?: number;
    collectionId?: number;
    inStock?: 1;
  },
  options?: { throttleKey?: string },
) =>
  api<ResponseWithPagination<ListProduct[]>>({
    url: '/variants',
    params,
    headers:
      options && options.throttleKey
        ? { 'X-Throttle-Key': options.throttleKey }
        : undefined,
  });

export const getMetals = (params?: { categoryId?: number }) =>
  api<Response<Metal[]>>({
    url: '/metals',
    params,
  });

export const getMetalGroups = (params?: { categoryId?: number }) =>
  api<Response<MetalGroup[]>>({
    url: '/metal-groups',
    params,
  });

export const getProductList = (params?: PaginationParams) =>
  api<ResponseWithPagination<{ id: number; slug: string }[]>>({
    url: '/products',
    params,
  });

export const getProduct = (
  params: { slug: string } | { variantId: number },
  options?: { throttleKey?: string },
) =>
  api<Response<ProductWithVariants>>({
    url: '/products/show',
    params,
    headers:
      options && options.throttleKey
        ? { 'X-Throttle-Key': options.throttleKey }
        : undefined,
  });

export const getCategoryList = () =>
  api<Response<ProductCategory[]>>({
    url: '/categories',
  });

export const getCategoryAttributes = (categoryId?: number) =>
  api<Response<CategoryAttribute[]>>({
    url: '/category-attributes',
    params: { categoryId },
  });

export const getReviews = async (
  productSlug?: string,
  params: Partial<
    Record<'dateDesc' | 'dateAsc' | 'ratingDesc' | 'ratingAsc', 1> &
      PaginationParams
  > = {
    dateDesc: 1,
  },
  options?: { throttleKey?: string },
) =>
  api<ResponseWithPagination<Review[]>>({
    url: `/reviews${productSlug ? `/${productSlug}` : ''}`,
    params,
    headers:
      options && options.throttleKey
        ? { 'X-Throttle-Key': options.throttleKey }
        : undefined,
  });

export const sendReview = (productId: number, data: NewReviewData) =>
  api({
    url: `/reviews/${productId}`,
    method: 'POST',
    data,
    transformData: { formData: true, snakeCase: { deep: false } },
  });

export const getRanges = () =>
  api<
    Response<{
      price: {
        min: number;
        max: number;
      };
    }>
  >({ url: '/variants/range' });

export const getPromotions = (params?: {
  categoryId?: number;
  limit?: number;
}) => api<Response<Promotion[]>>({ url: '/promos', params });
