import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface State {
  list: { id: number; type: 'diamond' | 'product' }[];

  toggle: (item: { id: number; type: 'diamond' | 'product' }) => void;
}

const useWishlist = create<State>()(
  persist(
    (set) => ({
      list: [],
      toggle(item) {
        set((state) => {
          const filteredList = state.list.filter(
            (el) => !(el.id === item.id && el.type === item.type),
          );

          if (state.list.length === filteredList.length) {
            return { list: [...filteredList, item] };
          }

          return {
            list: filteredList,
          };
        });
      },
    }),
    {
      name: 'wishlist',
      version: 1.0,
    },
  ),
);

export default useWishlist;
