import { useQuery } from '@tanstack/react-query';

import {
  getDiamondAttributes,
  getDiamondInfo,
  getDiamondShapes,
  getDiamondList,
  getDiamondRanges,
} from '@/api/diamond';
import { BaseAttribute } from '@/types/diamond';

const keys = {
  list: 'diamond/list',
  attributes: 'diamond/attributes',
  ranges: 'diamond/ranges',
  attributesShapes: 'diamond/attributes-shapes',
  diamond: 'diamond/info',
};

export const diamondAttributesQueryCreator = () => ({
  queryKey: [keys.attributes],
  queryFn: async () => {
    const { data } = await getDiamondAttributes();
    const filteredData = Object.entries(data).reduce(
      (
        acc: {
          [k: string]: BaseAttribute;
        },
        el,
      ) => ({
        ...acc,
        [el[0]]: {
          ...el[1],
          values: el[1].values.sort(
            (a, b) => (a.priority || 0) - (b.priority || 0),
          ),
        },
      }),
      {},
    );
    return {
      data: filteredData,
    };
  },
});

export const useDiamondAttributes = () =>
  useQuery(diamondAttributesQueryCreator());

export const diamondRangesQueryCreator = () => ({
  queryKey: [keys.ranges],
  queryFn: async () => {
    const result = await getDiamondRanges();
    const filteredData = Object.fromEntries(
      Object.entries(result.data).filter(
        ([, value]) => value?.max !== null && value?.min !== null,
      ),
    );
    return { data: filteredData };
  },
});

export const useDiamondRanges = () => useQuery(diamondRangesQueryCreator());

export const diamondListQueryCreator = (
  params?: Parameters<typeof getDiamondList>[0],
) => ({
  queryKey: [
    keys.list,
    JSON.stringify(
      Object.fromEntries(
        Object.entries(params || {}).sort((a, b) => a[0].localeCompare(b[0])),
      ),
    ),
  ],
  queryFn: () => getDiamondList(params),
});

export const useDiamondList = () => useQuery(diamondListQueryCreator());

export const diamondAttributesShapesQueryCreator = (
  ...[params]: Parameters<typeof getDiamondShapes>
) => ({
  queryKey: [keys.attributesShapes, JSON.stringify(params)],
  queryFn: async () => {
    const response = await getDiamondShapes(params);

    return {
      data: response.data.sort((a, b) => (a.priority || 0) - (b.priority || 0)),
    };
  },
});

export const diamondInfoQueryCreator = (id: number) => ({
  queryKey: [keys.diamond, id],
  queryFn: () => getDiamondInfo(id),
});
